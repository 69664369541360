<template>
  <div>
    <h2 v-html="$t('search_farm_title')"></h2>
    <v-text-field
      id="search_farm_agisid"
      :rules="[$rules.isNumericList]"
      v-model.trim="searchCardStore.Farm_agisId"
      :label="$t('search_farm_agisid')"
      type="string"
      @keyup.enter="search"
    />
    <v-text-field id="search_farm_berno" v-model="searchCardStore.Farm_berNo" :label="$t('search_farm_berno')" type="text" @keyup.enter="search" />
    <v-text-field id="search_farm_tvdid" v-model="searchCardStore.Farm_tvdId" :label="$t('search_farm_tvdid')" type="text" @keyup.enter="search" />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <v-text-field
        id="search_farm_cantonalid"
        v-model="searchCardStore.Farm_cantonalId"
        :label="$t('search_farm_cantonalid')"
        type="text"
        @keyup.enter="search"
      />
      <v-select
        id="search_farm_farmtypeid"
        v-model="searchCardStore.Farm_farmTypeId"
        :label="$t('search_farm_farmtypeid')"
        :items="farmTypes"
        :item-title="this.$getLangKey()"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
      <v-select
        id="search_farm_status"
        v-model="searchCardStore.Farm_status"
        :label="$t('search_farm_status')"
        :items="search_farm_status"
        item-title="text"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
      <v-select
        id="search_farm_cantonid"
        v-model="searchCardStore.Farm_cantonId"
        :label="$t('search_farm_cantonid')"
        :items="cantonsIncVirtual"
        :item-title="this.$getLangKey()"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { defineComponent } from 'vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import { useCantonsStore } from '@/store/enums/CantonsStore'

import { useFarmTypesStore } from '@/store/enums/FarmTypesStore'

export default defineComponent({
  name: 'searchFarm',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      search_farm_status: [
        { id: 1, text: this.$t('search_farm_active') },
        { id: 0, text: this.$t('search_farm_not_active') }
      ]
    }
  },
  computed: {
    farmTypes() {
      return useFarmTypesStore().farmTypes
    },
    cantonsIncVirtual() {
      return useCantonsStore().CantonsIncVirtual
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchFarmStore(), true)
    useFarmTypesStore().fetchFarmTypes()
    useCantonsStore().fetchCantonsIncVirtual()
  }
})
</script>
